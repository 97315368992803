<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn @click="$emit('dialog_balance')" x-large color="primary">
          اضافة رصيد <v-icon class="ms-3">payments</v-icon></v-btn
        >
        <v-btn @click="$emit('dialog_cancel_balance')" class='ms-3' x-large color="error">
          سحب رصيد <v-icon class="ms-3">sync</v-icon></v-btn
        >
      </v-col>


      <v-col cols="6">
        <v-card color="info">
          <v-card-text>
            <h3 class="white--text text-center">الرصيد</h3>
            <h2 class="text-center mt-3 white--text">
              {{
                balance
                  ? Intl.NumberFormat("en-US").format(balance.toFixed(2))
                  : 0
              }}
              USDT
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card color="blue-grey darken-1">
          <v-card-text>
            <h3 class="white--text text-center">الأستثمار</h3>
            <h2 class="text-center mt-3 white--text">
              {{
                certificates_balance
                  ? Intl.NumberFormat("en-US").format(
                      certificates_balance.toFixed(2)
                    )
                  : 0
              }}
              USDT
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Balance",
  props: ["balance", "certificates_balance"],
  data() {
    return {};
  },
};
</script>
