<template>
  <div>
    <v-card v-if="profile_loaded">
      <v-tabs v-model="tab">
        <v-tab class="green--text">الشهادات الجارية</v-tab>
        <v-tab class="red--text">الشهادات المنتهية</v-tab>
        <v-tab class="gray--text">الشهادات الملغية</v-tab>
      </v-tabs>

      <v-divider></v-divider>
      <v-tabs-items touchless v-model="tab">
        <v-tab-item>
          <v-card>
            <v-card-title>
              <v-text-field
                dense
                label="بحث"
                class=""
                hide-details
                v-model="search"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :search="search"
              :footer-props="$store.state.footer_props"
              :mobile-breakpoint="0"
              :headers="available_headers"
              :items="certificates.available"
            >
              <template v-slot:[`item.number`]="{ item }">
                {{ item.number }}#
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <v-btn
                  text
                  color="blue"
                  class="text-capitalize"
                  :to="{
                    name: 'customer_profile',
                    query: { user_info_identifier: item.user_id },
                  }"
                >
                  {{ item.name }}
                </v-btn>
              </template>

              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
              </template>
              <template v-slot:[`item.percent`]="{ item }">
                {{ item.percent }}%
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                {{ Intl.NumberFormat("en-US").format(item.amount.toFixed(2)) }}
              </template>
              <template v-slot:[`item.total_profit`]="{ item }">
                {{
                  Intl.NumberFormat("en-US").format(
                    ((item.percent / 100) * item.amount).toFixed(2)
                  )
                }}
              </template>
              <template v-slot:[`item.month_profit`]="{ item }">
                {{
                  Intl.NumberFormat("en-US").format(
                    ((item.percent / 100) * item.amount) /
                      item.profit_months.toFixed(2)
                  )
                }}
              </template>

              <template v-slot:[`item.remining_month`]="{ item }">
                {{ item.profit_months - item.profit_count }} شهر
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn @click="cancel_delete(item)" color="error" small>
                  الغاء الشهادة
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <!--/ still working    -->
        <v-tab-item>
          <v-card>
            <v-card-title>
              <v-text-field
                dense
                label="بحث"
                class=""
                hide-details
                v-model="search2"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :search="search2"
              :mobile-breakpoint="0"
              :items="certificates.expired"
              :headers="expired_headers"
              :footer-props="$store.state.footer_props"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-btn
                  text
                  color="blue"
                  class="text-capitalize"
                  :to="{
                    name: 'customer_profile',
                    query: { user_info_identifier: item.user_id },
                  }"
                >
                  {{ item.name }}
                </v-btn>
              </template>
              <template v-slot:[`item.number`]="{ item }">
                {{ item.number }}#
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
              </template>
              <template v-slot:[`item.percent`]="{ item }">
                {{ item.percent }}%
              </template>

              <template v-slot:[`item.total_profit`]="{ item }">
                {{ (item.percent / 100) * item.amount }}
              </template>
              <template v-slot:[`item.month_profit`]="{ item }">
                {{
                  Intl.NumberFormat("en-US").format(
                    ((item.percent / 100) * item.amount) /
                      item.profit_months.toFixed(2)
                  )
                }}
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <!--/ finished   -->
        <v-tab-item>
          <v-card>
            <v-card-title>
              <v-text-field
                dense
                label="بحث"
                class=""
                hide-details
                v-model="search3"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :search="search3"
              :mobile-breakpoint="0"
              :items="certificates.canceld"
              :headers="canceld_headers"
              :footer-props="$store.state.footer_props"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-btn
                  text
                  color="blue"
                  class="text-capitalize"
                  :to="{
                    name: 'customer_profile',
                    query: { user_info_identifier: item.user_id },
                  }"
                >
                  {{ item.name }}
                </v-btn>
              </template>
              <template v-slot:[`item.number`]="{ item }">
                {{ item.number }}#
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at.slice(0, item.created_at.indexOf("T")) }}
              </template>
              <template v-slot:[`item.percent`]="{ item }">
                {{ item.percent }}%
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  @click="open_dialog(item.number, item.cancel_reason)"
                  color="blue-grey darken-2"
                  class="white--text"
                  small
                >
                  عرض سبب الألغاء
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <!--/ canceld -->
      </v-tabs-items>
    </v-card>

    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-title> سبب الغاء الشهادة (#{{ number }}) </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <p>
            {{ reason }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = false" text color="success">تم</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="dialog_delete.prop">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            الغاء الشهادة (#{{ dialog_delete.number }})
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-textarea
              :rules="[(v) => !!v || 'برجاء كتابة سبب الالغاء']"
              v-model="dialog_delete.reason"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                سبب الألغاء"
              </template>
            </v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              :disabled="canceld_loading"
              :loading="canceld_loading"
              @click="cancel_certificate()"
              color="error"
              >الغاء الشهادة</v-btn
            >
            <v-btn @click="dialog_delete.prop = false" text>اغلاق</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar width="auto" right v-model="snackbar">
      تم الغاء الشهادة بنجاح
      <template v-slot:action="{ attrs }">
        <v-icon color="success" v-bind="attrs">check_circle</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Certificates",
  props: ["certificates", "profile_loaded", "test"],
  data() {
    return {
      search: "",
      search2: "",
      search3: "",
      snackbar: false,
      canceld_loading: false,
      data_loaded: false,
      tab: 0,
      dialog: false,
      dialog_delete: false,
      number: 0,
      reason: "",
      dialog_delete: {
        prop: false,
        id: null,
        reason: "",
        number: 0,
      },
      available_headers: [
        {
          value: "number",
          text: "رقم الشهادة",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الأنشاء",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الأنتهاء",
          align: "center",
        },
        {
          value: "amount",
          text: "مبلغ الأستثمار",
          align: "center",
        },
        {
          value: "percent",
          text: "النسبة",
          align: "center",
        },
        {
          value: "month_profit",
          text: "الربح الشهري",
          align: "center",
        },
        {
          value: "total_profit",
          text: "اجمالى الربح",
          align: "center",
        },
        {
          value: "remining_month",
          text: "الشهور المتبقية",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
      expired_headers: [
        {
          value: "number",
          text: "رقم الشهادة",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الأنشاء",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الأنتهاء",
          align: "center",
        },
        {
          value: "amount",
          text: "مبلغ الأستثمار",
          align: "center",
        },
        {
          value: "percent",
          text: "النسبة",
          align: "center",
        },
        {
          value: "month_profit",
          text: "الربح الشهري",
          align: "center",
        },
        {
          value: "total_profit",
          text: "اجمالى الربح",
          align: "center",
        },
      ],
      canceld_headers: [
        {
          value: "number",
          text: "رقم الشهادة",
          align: "center",
        },
        {
          value: "created_at",
          text: "تاريخ الأنشاء",
          align: "center",
        },
        {
          value: "end_date",
          text: "تاريخ الأنتهاء",
          align: "center",
        },
        {
          value: "canceled_date",
          text: "تاريخ الألغاء",
          align: "center",
        },
        {
          value: "amount",
          text: "مبلغ الأستثمار",
          align: "center",
        },
        {
          value: "percent",
          text: "النسبة",
          align: "center",
        },
        {
          value: "actions",
          text: "سبب الألغاء",
          align: "center",
        },
      ],
    };
  },
  computed: {
    toboy() {
      return this.certificates;
    },
  },
  methods: {
    open_dialog(number, reason) {
      this.number = number;
      this.reason = reason;
      this.dialog = true;
    },
    cancel_delete(item) {
      this.dialog_delete = {
        prop: true,
        id: item.id,
        reason: "",
        number: item.number,
      };
      console.log(this.dialog_delete);
    },
    cancel_certificate() {
      if (
        this.$refs.form.validate() &&
        this.dialog_delete.id &&
        this.dialog_delete.reason
      ) {
        this.canceld_loading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "all_certificates",
              method: "post",
            },
            data: {
              id: this.dialog_delete.id,
              reason: this.dialog_delete.reason,
            },
          })
          .then((res) => {
            this.$emit("certificate_canceld");
            this.snackbar = true;
            this.dialog_delete = {
              prop: false,
              id: null,
              reason: "",
              number: "",
            };
            this.$refs.form.resetValidation();
            this.canceld_loading = false;
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-slide-group__prev {
    display: none !important;
  }
  .number {
    font-size: 20px;
    color: #fff;
  }
}
</style>
