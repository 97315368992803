<template>
  <div>
    {{ dialog_cancel_balance }}
    <v-dialog @input="dialog_closed()" :value="dialog_balance" max-width="500">
      <v-form ref="form">
        <v-card>
          <v-card-title> اضافة رصيد </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-text-field
              v-model.number="balance.Amount"
              type="number"
              min="0"
              label="الرصيد"
              :rules="balance_rules"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn small @click="approve = true" color="success">حفظ</v-btn>
            <v-btn @click="dialog_closed" small text color="error">الغاء</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!--/ dialog   amount -->
    <v-dialog max-width="400" v-model="approve">
      <v-card>
        <v-card-title class="justify-center">
          تأكيد اضافة ({{
            balance.Amount
              ? Intl.NumberFormat("en-US").format(balance.Amount.toFixed(1))
              : 0
          }}
          USDT ؟ )
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="load_save"
            :loading="load_save"
            color="primary"
            @click="save()"
            >نعم</v-btn
          >
          <v-btn @click="approve = false" text color="error">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ dialog save  amount -->

    <v-dialog
      @input="dialog_closed()"
      :value="dialog_cancel_balance"
      max-width="500"
    >
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <v-icon color="error">sync</v-icon> سحب رصيد
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-alert v-if="alert" class="text-center" type="error" dense>
              <small>هذا المبلغ اكبر من مبلغ المتاح للعميل</small>
            </v-alert>
            <v-text-field
              v-model.number="back_amount"
              type="number"
              min="0"
              label="الرصيد"
              :rules="balance_rules"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn small @click="open_approve_back()" color="success"
              >حفظ</v-btn
            >
            <v-btn @click="dialog_closed" small text color="error">الغاء</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!--/ dialog back amount -->
    <v-dialog max-width="400" v-model="approve_back">
      <v-card>
        <v-card-title class="justify-center">
          تأكيد سحب ({{
            back_amount
              ? Intl.NumberFormat("en-US").format(back_amount.toFixed(1))
              : 0
          }}
          USDT ؟ )
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="load_save"
            :loading="load_save"
            color="primary"
            @click="save_back()"
            >نعم</v-btn
          >
          <v-btn @click="approve_back = false" text color="error">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ dialog save backm amount -->
  </div>
</template>
<script>
export default {
  props: ["dialog_balance", "dialog_cancel_balance", "current_balance"],
  name: "ChargeForm",
  data() {
    return {
      alert: false,
      approve_back: false,
      approve: false,
      load_save: false,
      balance: {
        Amount: 0,
        User_id: this.$store.state.user_romany_data.Admin_id,
        To_id: this.$route.query.user_info_identifier,
      },

      back_amount: 0,

      balance_rules: [
        (v) => !!v || "يجب وضع الرصيد",
        (v) => !isNaN(v) || "يجب  ان يكون رقم",
        (v) => !(v.toString().length > 8) || "يجب ان لا يتعدى ال 8 ارقام",
        (v) => !this.less_zero || "يجب  ان يكون اكبر من صفر",
      ],
    };
  },
  computed: {
    less_zero() {
      return this.balance.Amount < 0;
    },
    bigger_8() {
      return this.balance.Amount.toString().length > 8;
    },
  },
  methods: {
    dialog_closed() {
      this.$emit("dialog_closed");
      this.close();
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.approve = true;
      }
    },
    save() {
      this.load_save = true;
      this.$store
        .dispatch("public__request", {
          config: {
            method: "post",
            url: "balance",
          },
          data: this.balance,
        })
        .then((res) => {
          this.close();
          this.dialog_closed();
          this.$emit("save_balance");
        });
    },

    open_approve_back() {
      if (this.back_amount <= this.current_balance) {
        this.approve_back = true;
      } else {
        this.alert = true;
      }
    },
    save_back() {
      this.load_save = true;
      this.$store
        .dispatch("public__request", {
          config: {
            method: "put",
            url: `balance/${this.balance.To_id}`,
          },
          data: {
            amount: this.back_amount,
            admin_id: this.balance.User_id,
          },
        })
        .then((res) => {
          this.close();
          this.dialog_closed();
          this.$emit("save_balance");
        }).catch(res=> {
          console.log(res.response)
        })
    },
    close() {
      this.load_save = false;
      this.approve = false;
      this.approve_back = false;
      this.balance.Amount = 0;
      this.back_amount = 0;
      this.$refs.form.resetValidation();
    },
  },
};
</script>
