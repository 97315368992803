<template>
  <v-container>
    <v-row v-if="!profile_loaded">
      <v-col cols="12" sm="6">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <!--/ row -->

    <v-row class="mb-10">
      <v-col cols="12" sm="6" class="mb-3 mb-sm-0">
        <v-skeleton-loader
          v-if="!profile_loaded"
          type="article"
        ></v-skeleton-loader>
        <Balance
          v-if="profile_loaded"
          :balance="infos.balance"
          :certificates_balance="infos.certificates_balance"
          @dialog_balance="dialog_balance = true"
          @dialog_cancel_balance="dialog_cancel_balance = true"
        ></Balance>
      </v-col>
      <!--/ col -->

      <v-col cols="12" sm="6">
        <v-skeleton-loader
          v-if="!profile_loaded"
          type="article"
        ></v-skeleton-loader>
        <BasicInfo v-if="profile_loaded" :infos="infos"></BasicInfo>
      </v-col>
      <!--/ col -->

      <v-col cols="12">
        <Certificates
          @certificate_canceld="get_profile()"
          :profile_loaded="profile_loaded"
          :test="certificates.available"
          :certificates="certificates"
        ></Certificates>
      </v-col>
      <!--/ col -->
    </v-row>
    <!--/ row -->
    <v-skeleton-loader
      v-if="!profile_loaded"
      type="table"
      class="mt-10 mb-10"
    ></v-skeleton-loader>

    <v-skeleton-loader
      v-if="!profile_loaded"
      type="table"
      class="mt-10 mb-10"
    ></v-skeleton-loader>

    <TransTable
      v-if="profile_loaded"
      :transicitions="transicitions"
    ></TransTable>
    <!--/ all Customer Transactions -->

    <ChargeForm
      v-if="profile_loaded"
      @save_balance="get_profile"
      @dialog_closed="(dialog_balance = false), (dialog_cancel_balance = false)"
      :dialog_balance="dialog_balance"
      :current_balance="infos.balance"
      :dialog_cancel_balance="dialog_cancel_balance"
    ></ChargeForm>
    <!-- charge balance form -->
  </v-container>
</template>

<script>
import TransTable from "@/components/customer_profile/transactions";
import ChargeForm from "@/components/customer_profile/chargeForm";
import BasicInfo from "@/components/customer_profile/BasicInfo";
import Balance from "@/components/customer_profile/Balance";
import Certificates from "@/components/customer_profile/certificates";

export default {
  name: "customer_profile",
  data() {
    return {
      dialog_cancel_balance: false,
      profile_loaded: false,
      dialog_balance: false,
      tab: 0,
      dialog: false,
      dialog_delete: false,
      certificates: {
        expired: [],
        available: [],
        canceld: [],
      },
      transicitions: [],
      infos: [],
    };
  },
  components: {
    TransTable,
    ChargeForm,
    BasicInfo,
    Balance,
    Certificates,
  },
  methods: {
    get_profile() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `users/${this.$route.query.user_info_identifier}`,
            method: "get",
          },
        })
        .then((res) => {
          this.transicitions = res.data.data.all_trans;
          this.infos = res.data.data.basic_info;

          this.certificates = res.data.data.certificates;
          this.profile_loaded = true;
          // console.log(res.data.data);
        })
        .catch((err) => {
          this.$router.push("/Customers");
        });
    },
  },
  mounted() {
    this.get_profile();
  },
};
</script>
