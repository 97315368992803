<template>
  <div>
    <v-list elevation="2" dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>event</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ infos.created_at }}</v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>person</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ infos.name }}</v-list-item-content>
      </v-list-item>
      <!--/ customer name -->

      <v-list-item>
        <v-list-item-icon>
          <v-icon>phone_android</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ infos.phone }}</v-list-item-content>
      </v-list-item>
      <!--/ customer phone -->
      <v-list-item>
        <v-list-item-icon>
          <v-icon>alternate_email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ infos.email }}</v-list-item-content>
      </v-list-item>
      <!--/ customer email  -->

      <v-list-item class="align-center">
        <v-list-item-icon>
          <v-icon>badge</v-icon>
        </v-list-item-icon>
        <v-list-item-content>صورة الرقم القومى</v-list-item-content>

        <v-list-item-action>
          <v-btn @click="dialog2 = true" color="blue" text> عرض الصورة </v-btn>
        </v-list-item-action>
      </v-list-item>
      <!--/ customer identifier number  -->
    </v-list>

    <v-dialog max-width="500" v-model="dialog2">
      <v-card>
        <v-card-text class="pt-3">
          <div>
            <img
              :src="`https://app.zachi.app/${infos.front_ing}`"
              style="margin: 50px auto 0; width: 100%"
            />
          </div>
          <div>
            <img
              :src="`https://app.zachi.app/${infos.back_img}`"
              style="margin: 30px auto 20px; width: 100%"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="dialog2 = false" color="primary">تم</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["infos"],
  name: "BasicInfo",
  data() {
    return {
      dialog2: false,
    };
  },
};
</script>
